import { PermissionRow } from '@capturi/core'
import { t } from '@lingui/macro'
import type { UserPermissions } from 'pages/Organization/types'
export const getOtherPermissions = ({
  defaultRolePermissions,
  permissions,
  enableMasterTracker,
  enableText,
  isArchiveOrg,
}: {
  defaultRolePermissions: Partial<UserPermissions>
  permissions: UserPermissions
  enableText: boolean
  enableMasterTracker: boolean
  isArchiveOrg: boolean
}): PermissionRow[] => {
  const rows: PermissionRow[] = [
    {
      name: t`Quality Assurance`,
      key: 'qualityAssurance',
      tooltip: t`Permission to view and edit functionality regarding ‘quality assurance’`,
      hidden: enableText || isArchiveOrg,
    },
    {
      name: t`View reports`,
      key: 'viewReports',
      tooltip: t`Allows the user to view tailor made reports for your organisation`,
      hidden: isArchiveOrg ?? false,
    },
    {
      name: t`Master tracker`,
      key: 'editMasterTracker',
      tooltip: t`Allows the user to create and edit Master trackers`,
      disabled: !permissions.editTracker,
      hidden: !enableMasterTracker || isArchiveOrg,
    },
    {
      name: t`Playback`,
      key: 'playback',
      tooltip: t`Permission to play conversations within Capturi. Library snippets are NOT effected by this setting`,
      disabled: defaultRolePermissions?.playback == null,
      hidden: enableText,
    },
    {
      name: t`Download`,
      key: 'download',
      tooltip: t`Permission to download audio (conversations and library snippets) directly from the user interface within Capturi. This right presupposes the permission 'Playback'`,
      disabled:
        permissions.playback === false ||
        defaultRolePermissions?.download == null, // disable download, if playback is false
      hidden: enableText,
    },
    {
      name: t`Hide Agent Info`,
      key: 'hideUserInfo',
      tooltip: t`Permission to hide agent names and e-mails.`,
      hidden: isArchiveOrg,
    },
    {
      name: t`Archive`,
      key: 'archive',
      tooltip: t`Permission to show archive`,
      hidden: !isArchiveOrg,
    },
  ]

  return rows.filter((d) => !d.hidden)
}
